import { v4 as uuidv4 } from 'uuid'
import { StaticCanvas, FabricImage } from 'fabric'
import { getTypesetTextObjects } from '~/server/export/typesetting/typeset-text'
import { pageToChunks } from '~/services/export/chunks'
import { ExportTypesetPagePayload } from '~/types/canvas/exported-page'

const getTypesetPage = async ({ format, image, typesetTexts, width, chunkSize }: ExportTypesetPagePayload) => {
  const resizeFactor = width === 'original' ? 1 : width / image.width
  const dimensions = {
    width: width === 'original' ? image.width : width,
    height: image.height * resizeFactor,
  }

  const canvas = new StaticCanvas(`page-${uuidv4()}`, {
    ...dimensions
  })

  const page = new FabricImage(image, {
    top: 0,
    left: 0,
    scaleX: resizeFactor,
    scaleY: resizeFactor,
  })
  canvas.add(page)

  const textObjectsPromises = typesetTexts.map(async (entry) => {
    const boundingBox = JSON.parse(entry.bounding_box)
    const textObjects = await getTypesetTextObjects({
      boundingBox,
      pageImage: page,
      entry
    })
    return textObjects
  })

  const textObjects = await (await Promise.all(textObjectsPromises)).flat()

  canvas.add(...textObjects)

  let dataURL: string[] = []
  if(chunkSize){
    dataURL = await pageToChunks({ canvas, chunkSize, format })
  }else{
    dataURL.push(canvas.toDataURL({ format, multiplier: 1 }))
  }

  return {
    dimensions,
    dataURL
  }
}

export {
  getTypesetPage
}